/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-size: 100%;
  line-height: 1.5em;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2rem;
  line-height: 3rem;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin: 1.5rem 0; }

h2 {
  font-size: 1.5rem;
  line-height: 3rem;
  margin: 1.5rem 0; }

h3 {
  font-size: 1.17rem;
  line-height: 1.5rem;
  margin: 1.5rem 0; }

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 1.5rem 0; }

h5 {
  font-size: 0.83rem;
  line-height: 1.5rem;
  margin: 1.5rem 0; }

h6 {
  font-size: 0.67rem;
  line-height: 1.5rem;
  margin: 1.5rem 0; }

/* Grouping content
       ========================================================================== */
/**
       * Set 1 unit of vertical rhythm on the top and bottom margin.
       */
blockquote {
  margin: 1.5rem 40px; }

dl,
ol,
ul {
  margin: 1.5rem 0; }

/**
       * Turn off margins on nested lists.
       */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0; }

dd {
  margin: 0 0 0 40px; }

ol,
ul {
  padding: 0 0 0 40px; }

/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1.5rem 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
       * Set 1 unit of vertical rhythm on the top and bottom margin.
       */
p,
pre {
  margin: 1.5rem 0; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.5em;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
  /*
         * 1. Set 1 unit of vertical rhythm on the top and bottom margin.
         * 2. Set consistent space for the list style image.
         */
  margin: 1.5rem 0;
  /* 1 */
  padding: 0 0 0 40px;
  /* 2 */
  /**
         * Turn off margins on nested lists.
         */ }
  menu menu,
  ol menu,
  ul menu {
    margin: 0; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1.42857;
  color: #134e92; }
  h1 small,
  h1 .small,
  h2 small,
  h2 .small,
  h3 small,
  h3 .small,
  h4 small,
  h4 .small,
  h5 small,
  h5 .small,
  h6 small,
  h6 .small {
    font-weight: normal;
    line-height: 1;
    color: #4a4a4a; }

h1,
h2,
h3 {
  margin-top: 22px;
  margin-bottom: 11px; }
  h1 small,
  h1 .small,
  h2 small,
  h2 .small,
  h3 small,
  h3 .small {
    font-size: 65%; }

h4,
h5,
h6 {
  margin-top: 11px;
  margin-bottom: 11px; }
  h4 small,
  h4 .small,
  h5 small,
  h5 .small,
  h6 small,
  h6 .small {
    font-size: 75%; }

h1 {
  font-size: 2.5625rem; }

h2 {
  font-size: 1.75rem; }

h3 {
  font-size: 1.375rem; }

h4 {
  font-size: 1.1875rem; }

h5 {
  font-size: 1rem; }

h6 {
  font-size: 0.875rem; }

p {
  margin: 0 0 11px; }

a {
  color: #134e92;
  text-decoration: none;
  cursor: pointer;
  transition: color 86ms ease-out; }
  a:hover {
    color: #363636; }

small,
.small {
  font-size: 85%; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-nowrap {
  white-space: nowrap; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase, .initialism {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-muted {
  color: #7a7a7a; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 11px; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px; }
  .list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px; }

dl {
  margin-top: 0;
  margin-bottom: 22px; }

dt,
dd {
  line-height: 1.42857; }

dt {
  margin-top: 11px;
  margin-bottom: 11px;
  font-weight: 600; }

dd {
  margin-left: 1.15em; }

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #363636; }

.initialism {
  font-size: 90%; }

blockquote {
  padding: 11px 22px;
  margin: 0 0 22px;
  font-size: 20px;
  border-left: 5px solid #dbdbdb; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer,
  blockquote small,
  blockquote cite,
  blockquote .small {
    display: block;
    font-size: 80%;
    line-height: 1.42857;
    color: #7a7a7a; }
    blockquote footer::before,
    blockquote small::before,
    blockquote cite::before,
    blockquote .small::before {
      content: "\2014 \00A0"; }

address {
  margin-bottom: 22px;
  font-style: normal;
  line-height: 1.42857; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

.show-for-sr, .show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.branding {
  padding: 0;
  margin: 0;
  text-align: center; }
  .branding__image, .branding__subtitle {
    padding: 0 1.5rem; }
  .branding__image {
    display: block;
    max-width: 100%;
    margin: 1rem auto 0; }
  .branding__subtitle {
    display: block;
    margin: -.25rem auto .75rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #134e92; }

.navigation {
  padding: 0;
  margin: 1.5rem 0;
  border-top: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5; }

.menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0;
  margin: 0;
  line-height: 1;
  list-style: none; }
  .menu__item {
    flex: 0 1 auto;
    margin: .75rem;
    font-size: 1rem; }
    .menu__item a {
      color: #121212; }
    .menu__item a:hover,
    .menu__item a:focus {
      color: #134e92; }
    .menu__item--active a {
      font-weight: 600;
      color: #134e92; }

.slider {
  position: relative; }
  .slider__item {
    position: relative;
    height: 250px;
    overflow: hidden;
    text-align: center; }
    .slider__item h2 {
      text-shadow: 1px 1px 0px #7a7a7a, 1px -1px 0px #7a7a7a, -1px 1px 0px #7a7a7a, -1px -1px 0px #7a7a7a; }
    html .slider__item h2 {
      margin-top: 2%;
      line-height: 1.1;
      color: white;
      text-align: left; }
  .slider__arrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    padding: 0;
    margin: -9px 0 0;
    line-height: 1;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    opacity: .75; }
    .slider__arrow--prev {
      left: .5rem; }
    .slider__arrow--next {
      right: .5rem; }
  .slider:hover .slider__arrow {
    opacity: 1; }
  .slider figure {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 250px;
    margin: 0;
    overflow: hidden;
    line-height: 1; }
    .slider figure img {
      position: relative;
      top: -50%;
      min-width: 100%; }
    .slider figure figcaption {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: .1em;
      margin: .5em;
      font-size: .8em;
      line-height: 1;
      background-color: rgba(219, 219, 219, 0.75); }

.button {
  padding: .275em .5em;
  line-height: 1;
  cursor: pointer;
  background-color: #dbdbdb;
  border: 1px solid #7a7a7a; }
  .button:hover {
    background-color: #b5b5b5; }

.main__header h2 {
  font-size: 2.5625rem;
  font-family: "Zeyada", cursive;
  font-weight: normal;
  color: #134e92; }

.main figure {
  margin-top: 0;
  line-height: 1.1; }
  .main figure.left {
    float: left;
    margin-left: 0; }
  .main figure.right {
    float: right;
    margin-right: 0; }
  .main figure figcaption {
    font-size: .85em; }

.main .kukulili-labs__logo {
  margin-right: 0;
  margin-left: 0; }
  .main .kukulili-labs__logo img {
    width: 250px;
    height: auto; }

.therapies {
  padding: 0;
  margin: 0; }
  @media screen and (min-width: 769px), print {
    .therapies {
      column-count: 2;
      column-gap: 3rem; } }
  .therapies__item {
    display: inline-block; }

.therapy__link {
  color: #121212;
  transition: color 86ms ease-out; }
  .therapy__link:hover {
    color: #134e92;
    text-decoration: none; }
  .therapy__link svg {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: inline-block;
    width: 3rem;
    height: auto;
    opacity: .1;
    transition: right 350ms ease-out, opacity 86ms ease-out;
    fill: #134e92; }

.therapy__link:hover svg {
  right: -2.5rem;
  opacity: 1; }
  @media screen and (max-width: 959px) {
    .therapy__link:hover svg {
      right: -.5rem; } }

.therapy__caption {
  position: relative; }

.uniform__potty {
  position: absolute;
  left: -9999px; }

.form__row {
  display: flex;
  flex-flow: row wrap;
  margin: 1em 0 .75em; }

.form__row--error .form__input {
  border-color: #f34213; }

.form__label {
  flex: 1 0 auto;
  width: 30%;
  padding: .2em 1rem .2em 0;
  font-size: 600;
  text-align: right; }

.form__input {
  flex: 1 0 auto;
  width: 70%;
  padding: .1em .2em;
  background-color: white;
  border: 1px solid #7a7a7a; }

.form__error {
  color: #f34213; }

.form__error, .form__info, .form__captcha {
  flex: 1 0 auto;
  width: 70%;
  margin: 0 0 0 30%;
  font-size: .85em; }

.form__submit {
  flex: 1 0 auto;
  width: 70%;
  margin-left: 30%; }

@media screen and (max-width: 768px) {
  .form__row {
    flex-wrap: wrap; }
  .form__label, .form__input, .form__error, .form__info, .form__captcha, .form__submit {
    width: 100%;
    margin-left: 0;
    text-align: left; } }

.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid #7a7a7a; }
  .footer__item {
    flex: 1 0 30%;
    max-width: 30%; }
    @media screen and (min-width: 769px) and (max-width: 959px) {
      .footer__item {
        margin-bottom: 22px; }
        .footer__item:nth-child(1) {
          flex-basis: 50%;
          max-width: 50%;
          order: 1; }
        html .footer__item:nth-child(2) {
          flex-basis: 100%;
          width: 100%;
          max-width: 100%;
          margin-bottom: 22px;
          order: 3; }
        .footer__item:nth-child(3) {
          flex-basis: 50%;
          max-width: 50%;
          order: 2; } }
    @media screen and (max-width: 768px) {
      .footer__item,
      html .footer__item:nth-child(2) {
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
        margin-bottom: 22px; } }
    .footer__item:nth-child(2) {
      max-width: 25%; }
  .footer__copyright {
    font-size: .9rem; }
  .footer h2,
  .footer h3,
  .footer h4 {
    margin-top: 0;
    line-height: 1.1;
    color: #134e92; }
  .footer h2,
  .footer h3 {
    font-family: "Zeyada", cursive;
    font-weight: normal; }
  .footer h3 {
    font-size: 1.75rem; }
  .footer h4 {
    font-size: 1rem;
    margin-top: 2em; }
  .footer figure {
    margin: 0 0 1em; }

*, *::before, *::after {
  box-sizing: border-box; }

html,
body {
  font-size: 16px; }
  @media screen and (max-width: 768px) {
    html,
    body {
      font-size: 13.91304px; } }

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #121212;
  background-color: whitesmoke;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slider__item h2, .wrap {
  max-width: 960px;
  padding: 0 1.5rem;
  margin: 0 auto; }
